a<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="medical-3-2">醫療議題</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">分娩計畫</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="title-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up">選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜分娩計畫｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>規劃生產計畫書，從3階段8項目著手，孕媽咪也能表達對生產的期待！</h3>
                        <p>生產計畫書的內容因人而異，有些產婦依照醫療院所提供的制式表格填寫，也有些自行擬定，但無論如何都需對生產流程有初步了解，陳鈺萍醫師建議，撰寫計畫生產計畫書前，可先思考待產、生產及產後等3階段，由以下8項目著手，也可再依照個人需求做增減。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>1.陪伴者</h3>
                        <p>產婦可先思考希望由誰來陪產，通常為自己最為信任的對象，可讓自己感受到安心與支持的人，例如：老公、家人、閨密等，並非僅限一人。不過，近期受到疫情影響，大多限定僅能由一人陪產（多是老公）。建議陪產者與產婦一同上產前教育課程，了解生產程序與可能遇到的情形，在必要時，就能提供產婦最大的支持。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>2.待產環境</h3>
                        <p>待產環境容易影響產婦心情，產婦可先設想自己在什麼樣的氛圍下較容易放鬆或感到安心，將這些元素帶進待產、生產場所，將能使產婦更專注於生產，例如：現場燈光的明暗、音樂類型與香氛種類等。
                        </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>3.待產姿勢</h3>
                        <p>進入待產階段，醫護人員通常會為產婦裝上胎心音監測器，以掌控子宮收縮情況、胎心音與胎動。不過，對有些產婦而言，裝上胎心音監測器不僅不舒服，一旦裝上，就只能待在產床上，無法進行適當活動來幫助生產。
                            陳鈺萍醫師指出，除了生產具高風險的產婦必須持續監測胎心音外，低風險的產婦可根據自身需求來選擇連續、間斷或胎兒有狀況時再使用，如此也能以更為舒服的姿勢來待產，適度走動更能加快子宮頸擴張速度。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>4.減痛、加速產程</h3>
                        <p>許多產婦擔心無法克服對產痛的恐懼，要求以施打減痛等醫療介入，其實也可藉由其他非藥物的方式來克服，例如：按摩、陪伴、冥想、分散注意力等，或是透過其他姿勢、生產球等來舒緩產痛，產婦可依照自身喜好來選擇。
                            對於加速產程的進行方式，產婦可於生產計畫書中表明，並與產科醫師討論，例如：哪些情況下必須使用人工破水、催生藥物等。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>5.生產姿勢</h3>
                        <p>目前大多數產婦仍於醫院、診所產檯上生產，不過，其實生產姿勢是可被選擇的，例如：蹲式、半坐臥姿、跪趴姿等。陳鈺萍醫師形容，生產有如「解一坨大便」，躺於產檯上並不利於生產，建議孕期就與醫護人員、助產師討論自身的身體狀態，來決定最合適的生產姿勢。
</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>6.會陰切開</h3>
                        <p>進行會陰切開的目的為加快產程、防止嚴重撕裂傷延伸至肛門或直腸，但目前已有許多醫學實證證實生產時不需常規剪會陰。陳鈺萍醫師說明，產婦分娩過程，外陰部會延展、變薄，使胎兒胎頭慢慢滑出，過程中，陰道口前後左右肌肉張力均等，會陰部並不會撕裂。相較於使用會陰切開，最低傷害為2級中度裂傷，不切開會陰則可能無傷或僅有1級輕度裂傷。
                        不過，若生產過程中出現緊急情況，仍須使用，例如：出現胎兒無法順利娩出，心跳微弱、需使用真空吸引，但會陰部延展度不夠，無法放入真空吸引器時，就必須進行會陰切開。
                        </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>7.寶寶斷臍方式</h3>
                        <p>為了讓爸爸對生產一事更有參與感，可由爸爸來斷臍。一般常規做法，為了避免增加產婦出血風險與寶寶黃疸需照光等疑慮，寶寶出生後會採取立即斷臍。不過，最新醫學實證已破解上述說法，目前最新做法為「延遲斷臍」，除了可給寶寶更多的血容積、增加寶寶血色素值，也能減少貧血機率。
                        </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>8.母嬰肌膚接觸</h3>
                        <p>寶寶出生後，產婦可選擇抱寶寶的時機點，例如：生產後擦乾即刻肌膚接觸、量完體重後、擦乾包好後與給予眼藥之前等。而產後寶寶與媽媽立即肌膚接觸，除了讓寶寶更有安全感，增加親子間的親密連結，更有益於寶寶順利含乳。</p>
                      </li>
                      <li data-aos="fade-up">
                      <p>資料來源：<a href="https://257085.sfaa.gov.tw/" target="_blank">未成年懷孕求助網站</a><!--<a class="fs-5 text-success" href="https://www.mombaby.com.tw/" target="_blank">《媽媽寶寶》</a>-->
                      <br>
                      <a class="fs-5 text-danger" href="https://257085.sfaa.gov.tw/information_detail.php?Key=26" target="_blank">規劃生產計畫書，從3階段8項目著手，孕媽咪也能表達對生產的期待！</a>
                      </p>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
